import React from "react";
import { Helmet } from "react-helmet";
import { Box } from "ticeboxes";
import { LayoutContainer, Logo, P, ThemeWrapper } from "time-tracker-app-lib";
import Footer from "../components/Footer";

interface Props {}

const NotFound: React.FC<Props> = () => {
  return (
    <ThemeWrapper>
      <Helmet>
        <title>Time Tracker App - Not found</title>
      </Helmet>

      <header>
        <Box className="layout-mt-5 layout-mb-5">
          <Logo />
        </Box>
      </header>

      <main>
        <LayoutContainer>
          <P cursive>Sorry, page not found</P>
        </LayoutContainer>
      </main>

      <Footer />
    </ThemeWrapper>
  );
};

export default NotFound;
